import { React, useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  TextField,
  RadioGroup,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Radio,
  MenuItem,
  InputLabel,
  Select,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import {
  GetAllFundamentalRoles,
  AddAdmin,
} from "../../api/endpoints";
import {ExpandMore, } from "@mui/icons-material";
import Loading from "../../layout/Loading";
import { successNote, toastErrorNotify } from "../../helpers/toastNotify";
import * as Yup from "yup";
import apiClient from "../../api/apiClient"
import {useGetAllDepartmentsQuery} from '../../services/Departments/departmentSlice'


const CreateAdminForm = () => {
  const [roleData, setRoleData] = useState(null);
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const roleName = selectedRole?.split(" ").shift();
  const [, setRoleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setRadioLoading] = useState(false);
  const capitalizedRoleName =
    roleName?.charAt(0).toUpperCase() + roleName?.slice(1);
  const [departmentSelected, setDepartmentSelected] = useState(false);
  const yupErrors = {};
  const [validationErrors, setValidationErrors] = useState({});
  const [helperText, setHelperText] = useState(null);
  const [helperSelectText, setHelperSelectText] = useState(null);


  // ! YUP for validation check

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("En gylding e-postadresse er påkrevd")
      .required("En gylding e-postadresse er påkrevd"),
  });

  // ! To Get and Show All Roles in Radio Button

  async function getAllRoles() {
    setRadioLoading(true);
    const params = {
      email,
      isActive: true,
    };

    const response = await apiClient.get(GetAllFundamentalRoles, {
      params,
    });
    // console.log(response.data.data);
    response.data?.data.map((item) => {
      return item.displayName;
    });
    setRoleData(response.data.data);
    setRadioLoading(false);
  }

  // ! To Get All Departments to show in select
   const { data: allDepartments, isLoading: allDepsLoading, error } = useGetAllDepartmentsQuery();

  function clearFields(event) {
    Array.from(event.target).forEach((e) => (e.value = ""));
  }

  // ! Add Admin Form

  async function handleClick(e) {
    e.preventDefault();
    setLoading(true);
    const initialValues = {
      email,
    };
    const params = {
      email,
      type: capitalizedRoleName,
      departmentName: selectedDepartment,
    };
    if (selectedRole === null) {
      setHelperText("Vennligst velg et alternativ");
    } else if (selectedRole === "Department" && !selectedDepartment) {
      setHelperSelectText("Vennligst velg én avdeling");
    } else {
      setHelperText("");
    }
    try {
      await validationSchema.validate(initialValues, { abortEarly: false });
      //! If validation succeeds, proceed with form submission
      await apiClient.post(AddAdmin, params,);
      successNote("Registrerings-e-post er sendt til den nye administratoren");
      clearFields(e);
      setSelectedRole(null);
      setSelectedDepartment("");
    } catch (err) {
      if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      } else if (!err.response) {
        toastErrorNotify("Ingen respons fra serveren");
      } else if (err.response.data?.data) {
        toastErrorNotify(err.response.data.data?.validationErrors[0]);
      } else if (err.response.data.data === null) {
        toastErrorNotify(err.response.data?.message);
      } else {
        toastErrorNotify("Feilet");
      }
    } finally {
      setLoading(false);
    }
  }

  // ! Handle Change Functions

  const handleRadioChange = (event, id) => {
    if (selectedRole !== null) {
      setSelectedRole(null);
    } else if (roleName === "Department") setDepartmentSelected(true);
    setSelectedRole(event.target.value);
    setRoleId(id);
  };

  const handleSelectChange = (event) => {
    if (roleName === "Department") {
      setSelectedDepartment(event.target.value);
    } else if (roleName !== "Department") {
      setSelectedDepartment("");
    }
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  function getDisplayNameText(roleName) {
    if (roleName === 'org.admin.department') {
      return 'Avdelings-administrator';
    } else if (roleName === 'org.admin.assistant') {
      return 'Assistent-administrator';
    } else if (roleName === 'org.admin.course') {
      return 'Kurs-administrator';
    } else if (roleName === 'org.admin.integration') {
      return 'Integrasjons-administrator';
     }
  }
  

  return (
    <Paper sx={{ p: 2, m: 2, maxWidth: 950 }}>
      <Typography variant="h5" align="center" sx={{ mb: 2 }}>
        Opprett en administrator
      </Typography>
      <Typography variant="body1" sx={{ m: 3 }}>
        Personen du oppretter admin for må være registrert på e-post. <br />
        Hvis du ønsker å opprette en avdelingsadministrator, må du velge
        avdeling.
      </Typography>
      <Box component="form" noValidate onSubmit={handleClick} sx={{ mx: 2 }}>
        <TextField
          required
          fullWidth
          id="outlined-required"
          label="Skriv inn e-postadressen til mottaker av invitasjonen"
          onChange={handleInputChange}
          error={Boolean(validationErrors.email)}
          helperText={validationErrors.email}
        />
        {/* <ErrorMessage error={} /> */}
        <br />
        <Box display="flex" alignItems="center">
  <Typography variant="h6" sx={{ m: 3 }}>
    Opprett en administrator for
  </Typography>
</Box>
        {/* // ! Map all the admin roles or loading */}
        {roleData?.length > 0 ? (
          roleData?.map((item) => (
            <Accordion key={item.id}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                id={item.displayName}
              >
                <FormControl error={(helperText && !selectedRole)}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue={item?.apiCallingName}
                    name="radio-buttons-group"
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      value={item.apiCallingName}
                      control={<Radio />}
                      label={getDisplayNameText(item.roleName)}
                      name={item.displayName}
                      checked={selectedRole === item.apiCallingName}
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionSummary>
              <AccordionDetails>
                {item.roleName === "org.admin.department" ? (
                  <>
                    <Typography>{item.detail}</Typography>
                    <FormControl
                      fullWidth
                      sx={{ mt: 2 }}
                      error={helperSelectText && !selectedDepartment}
                    >
                      <InputLabel id="dempartment-select-label">
                        Velg department for avdelingsadministrator
                      </InputLabel>
                      <Select
                        labelId="department-select"
                        id="department-select"
                        label="Velg department for admin"
                        value={selectedDepartment}
                        onChange={handleSelectChange}
                        disabled={departmentSelected}
                      >
                        {/* // ! Map departments or loading */}
                        {!allDepsLoading ? (
                          allDepartments?.data.map((item, key) => (
                            <MenuItem key={key} value={item.departmentName}>
                              {item.departmentName}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem sx={{color:'#858585'}}>
                          <p style={{marginRight:'8px'}}>Avdelingsliste lastes inn  </p>
                          <Loading size={18} color={'#5177b8'} />
                        </MenuItem>
                        )}
                      </Select>
                      {helperSelectText && !selectedDepartment && (
                        <FormHelperText sx={{ color: "red" }}>
                          {helperSelectText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                ) : (
                  <Typography>{item.detail}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Grid item display="flex" justifyContent="center" xs={12}>
            <Loading color={"#858585"} size={30} />
          </Grid>
        )}

        <Grid item display="flex" justifyContent="center" xs={12}>
          <FormHelperText sx={{ color: "red" }}>{helperText}</FormHelperText>
        </Grid>

        <Grid item display="flex" justifyContent="center" xs={12}>
          <Button
            type="submit"
            variant="contained"
            className="PrimaryButton"
            disabled={loading}
            sx={{ height: 40, width: 200, m: 3 }}
          >
            {loading ? <Loading color={"#fff"} size={25} /> : "Legg til"}
          </Button>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CreateAdminForm;
