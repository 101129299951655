import getCookie from "../../utils/cookieUtils";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CheckDepartments,
  SetUpDepartments,
  ResetDepartments,
  GetUsersInConflict,
  GetAllDepartments,
} from "../../api/endpoints";

export const getDepChangesApi = createApi({
  reducerPath: 'getDepChanges',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const token = getCookie('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllDepartments: builder.query({
      query: () => ({ url: GetAllDepartments, method: 'GET' }),
      skipCache: true
    }),
    checkDepartments: builder.query({
      query: () => ({ url: CheckDepartments, method: 'GET' }),
      // skipCache: true
    }),
    getUsersInConflict: builder.query({
      query: () => ({ url: GetUsersInConflict, method: 'GET' }),
      skipCache: true,
      providesTags: (result) => [{ type: 'GetUsersInConflict', id: 'LIST' }]
    }),
    resetDepartments: builder.mutation({
      query: () => ({ url: ResetDepartments, method: 'GET' }), 
      body: null,
      // invalidatesTags: [{ type: 'GetUsersInConflict' }],
      // skipCache: true
    }),
    setUpDepartments: builder.mutation({
      query: () => ({ url: SetUpDepartments, method: 'POST' }),
      body: null,
      // skipCache: true,
      // invalidatesTags: [{ type: 'GetUsersInConflict' }]
    }),
  })
});

export const {
  useGetAllDepartmentsQuery,
  useCheckDepartmentsQuery,
  useGetUsersInConflictQuery,
  useResetDepartmentsMutation,
  useSetUpDepartmentsMutation
} = getDepChangesApi;
